import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts"
import SectionGrid2 from "../components/sections/grid/grid2"
import HeroInner from "../components/sections/hero/inner"
import { SubTitle, Title } from "../components/atoms/titles"
import { Html } from "../components/atoms/text"

const IndexPage = ({ data: { node } }) => {

  //console.log(node)
  return (
    <Layout>
      <Hero data={node.relationships.hero} />
      <Section1 data={node.relationships.section1[0]} />
      <Section2 data={node.relationships.section1[1]} />
      <Section3 data={node.relationships.section1[2]} />
    </Layout>
  )
}


const Hero = ({ data }) => {


  const { title, subtitle } = data
  const url = data.relationships.image.localFile.publicURL
  const action1 = data.relationships.actions[0]
  const action2 = data.relationships.actions[1]

  return (
    <HeroInner
      title={title}
      style={{ backgroundImage: `url("${url}")` }}
      description={subtitle}
      action1={action1}
      action2={action2}
    />
  )

}

const Section1 = ({ data }) => {

  const { title } = data

  const image = {
    src: data.relationships.image.localFile.publicURL,
    alt: data.image.alt
  }

  const html = data.description[0].processed

  return (

    <SectionGrid2 className="" classNameGrid="gap-0">
      <div className=" p-24">
        <Title className="text-[55px] font-bold">{title}</Title>
        <Html className="text-[25px] user-content">{html}</Html>
      </div>
      <div className="mr-auto">
        <img src={image.src} alt={image.alt} />
      </div>
    </SectionGrid2>

  )
}

const Section2 = ({ data }) => {

  const { title } = data

  const html1 = data.description[0].processed
  const html2 = data.description[1].processed

  return (
    <SectionGrid2
      className="bg-dark text-white py-24 "
      classNameTitle="text-right text-[50px] text-bold container"
      classNameGrid="gap-12 container py-12"
      title={title}
    >
      <Html className="text-[40px] user-content">{html1}</Html>
      <Html className="text-[25px] user-content">{html2}</Html>
    </SectionGrid2>
  )

}


const Section3 = ({ data }) => {

  //console.log('Section3', data)

  const { title, subtitle } = data

  const image = {
    src: data.relationships.image.localFile.publicURL,
    alt: data.image.alt
  }

  const html = data.description[0].processed


  return (
    <SectionGrid2
      className=""
      classNameGrid="gap-0"
    >
      <div className=" p-24 text-[#707070]">
        <SubTitle className="text-[50px] font-light">{subtitle}</SubTitle>
        <Title className="text-[55px] font-bold">{title}</Title>
        <Html className="text-[25px] user-content">{html}</Html>
      </div>
      <div className="mr-auto">
        <img src={image.src} alt={image.alt} />
      </div>
    </SectionGrid2>

  )

}

export default IndexPage


export const query = graphql`
  query about($id: String!) {
    node: nodeAbout(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path{
        alias
      }
      relationships {
        hero: field_hero {
          ...HeroContent
        }
        section1: field_paragraph_multi {
          ...BlockContent
        }

      }
    }
  }
`
